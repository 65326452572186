
<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Mis entregables</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small dark color="green"
              @click="exportExcel( mis_entregables  , 'mis_entregables_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

            <v-container fluid>
              <v-row justify="space-around" align="center">
                <v-radio-group
                  v-model="estatus"
                  mandatory
                  row
                  class="d-flex align-center"
                >
                  <v-card-text>
                    <span>Filtrar por estatus</span>
                  </v-card-text>
                  <v-radio
                    label="Todos"
                    :value="10"
                  ></v-radio>
                  <v-radio
                    label="En proceso"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="Aprobado"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Con evidencia"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Rechazado"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-container>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="space-between" align="center">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="fechaInicio"
                  label="Fecha inicio"
                  type="date"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="fechaFin"
                  label="Fecha fin"
                  type="date"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="3">
              <v-card outlined>
                <v-card-title>
                  <v-icon left>mdi-file-document-multiple</v-icon>
                  Cantidad de Entregables
                  <v-spacer></v-spacer>
                  <v-chip color="primary" text-color="white">
                    {{ reporteEntregables.total }}
                  </v-chip>
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="warning" text-color="white" small>
                            {{ reporteEntregables.enProceso }}
                          </v-chip>
                          <span color="warning" text-color="white" small class="ml-2 font-weight-bold">
                            {{ reporteEntregables.enProcesoDesc }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="success" text-color="white" small>
                            {{ reporteEntregables.aprobados }}
                          </v-chip>
                          <span class="ml-2 font-weight-bold">
                            {{ reporteEntregables.aprobadosDesc }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="info" text-color="white" small>
                            {{ reporteEntregables.conEvidencia }}
                          </v-chip>
                          <span class="ml-2 font-weight-bold">
                            {{ reporteEntregables.conEvidenciaDesc }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="error" text-color="white" small>
                            {{ reporteEntregables.rechazados }}
                          </v-chip>
                          <span class="ml-2 font-weight-bold">
                            {{ reporteEntregables.rechazadosDesc }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="gray" small>
                            {{ reporteEntregables.avance }}
                          </v-chip>
                          <span class="ml-2 font-weight-bold">
                            {{ reporteEntregables.avanceDesc }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
	  		    <v-row>
	  		    	<v-col cols="12">
          <!-- Tabla de items -->
							  <v-data-table
							    :headers="headers"
							    :items="filterMisEntregables"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
                  <!-- <template v-slot:item.evidencia="{item}">
                    <v-img :src="url + item.evidencia" contain max-height="100" aspect-ratio="2"/>
                  </template> -->
                  <template v-slot:item.evidencia="{ item }">
                    <v-img
                      :src="url + item.evidencia"
                      contain
                      max-height="100"
                      aspect-ratio="2"
                      @click="openImageDialog(item.evidencia)"
                      class="clickable-image"
                    />
                  </template>

                  <template v-slot:item.mensaje="{ item }">
                    <v-btn
                      v-if="item.mensaje && item.mensaje.trim() !== ''"
                      color="info"
                      elevation="3"
                      x-small
                      @click="verMensaje(item.mensaje)"
                    >Ver</v-btn>
                  </template>

                  <template v-slot:item.estatus="{ item }">
                    <v-chip
                      :color="getEstatusColor(item.estatus)"
                      dark
                    >
                      {{ getEstatusTexto(item.estatus) }}
                    </v-chip>
                  </template>

                  <template v-slot:item.dias_retraso="{ item }">
                    <v-icon
                      :color="item.dias_retraso === 'con retraso' ? 'red' : 'green'"
                    >
                      {{ item.dias_retraso === 'con retraso' ? 'mdi-clock-alert-outline' : 'mdi-clock-check-outline' }}
                    </v-icon>
                  </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn
							    		color="primary"
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn
							    		color="error"
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

    <v-dialog
      v-model="mensajeDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-h6">Mensaje</v-card-title>
        <v-card-text>{{ currentMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="mensajeDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
       v-model="imageDialog"
       max-width="90%"
       max-height="90%"
     >
       <v-card>
         <v-card-title class="text-h5">
           Imagen
           <v-spacer></v-spacer>
           <v-btn
             icon
             @click="imageDialog = false"
           >
             <v-icon>mdi-close</v-icon>
           </v-btn>
         </v-card-title>
         <v-card-text>
           <v-img
             :src="fullImageSrc"
             contain
             max-height="70vh"
           ></v-img>
         </v-card-text>
       </v-card>
     </v-dialog>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idmis_entregables }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12" md="12">
              <v-text-field
                v-model="editedItem.titulo"
                label="Titulo"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

             <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_entrega"
                label="Fecha de Entrega"
                type="date"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

             <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.hora_entrega"
                label="Hora de Entrega"
                type="time"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

             <v-col cols="12" md="12">
              <v-textarea
                v-model="editedItem.notas"
                label="Notas"
                filled
                hide-details
                dense
                name="input-7-4"
              ></v-textarea>
            </v-col>

            <v-col  v-if="showFileInput" cols="12" md="12">
              <v-file-input
                small-chips
                v-model="files"
                accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
                placeholder="Selecciona tus imagenes"
                prepend-icon="mdi-camera"
                @change="cargarFotos()"
              ></v-file-input>
            </v-col>

            <v-col v-if="editedItem.evidencia" cols="12" md="12">
              <v-img
                :src="url + editedItem.evidencia"
                contain
                max-height="100"
                @click="openImageDialog(editedItem.evidencia)"
                class="clickable-image"
              ></v-img>
            </v-col>

          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>

  import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      fechaInicio: '', // Filtro por fecha inicio
      fechaFin: '', // Filtro por fecha fin

      estatus:10, // Estatus para arrojar todos los entregables
      currentMessage: '', // Aquí guardaremos el mensaje seleccionado
      mensajeDialog: false,
      showFileInput: false,
      imageDialog: false,
      fullImageSrc: '',
      fotos:[],
      files: [],
      vistaPrevias:[],

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        idmis_entregables:0,
				titulo:'',
        creo:0,
				fecha_entrega:'',
				hora_entrega:'',
        mensaje:'',
				notas:'',
        evidencia:'',
        vistaPrevias:[],
        estatus:0,
        deleted:0,
      },

      defaultItem: {
        idmis_entregables:0,
				titulo:'',
        creo:0,
				fecha_entrega:'',
				hora_entrega:'',
        mensaje:'',
				notas:'',
        evidencia:'',
        vistaPrevias:[],
        estatus:0,
        deleted:0,
      },

      // Filtros:
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      mis_entregables: [],
      headers: [
        { text: 'Titulo'               , value: 'titulo'               },
        { text: 'Creó'                 , value: 'nombre_completo'      },
        { text: 'Fecha de Entrega'     , value: 'fecha_entrega'        },
        { text: 'Hora de entrega'      , value: 'hora_entrega'         },
        { text: 'Notas'                , value: 'notas'                },
        { text: 'Evidencia'            , value: 'evidencia', sortable: false },
        { text: 'Estatus'              , value: 'estatus'              },
        { text: 'Mensaje'              , value: 'mensaje'              },
        { text: 'Tiempo'               , value: 'dias_retraso'         },
        { text: 'Actions'              , value: 'actions', sortable: false },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar entregable' : 'Editar entregable'
      },

      filterMisEntregables(){
        let data = this.mis_entregables
          if(this.estatus !== 10){
            data = data.filter(el => el.estatus === this.estatus)
          }

          // Filtrar por rango de fechas si se proporcionan fecha de inicio y fecha de fin
          if (this.fechaInicio && this.fechaFin) {
            data = data.filter(el => {
              const entregaDate = new Date(el.fecha_entrega);
              const startDate = new Date(this.fechaInicio);
              const endDate = new Date(this.fechaFin);

              // Incluir fechas que estén dentro del rango (inclusive)
              return entregaDate >= startDate && entregaDate <= endDate;
            });
          }


        return data
      },

      reporteEntregables() {
        const data = this.mis_entregables;

        // Conteo de entregables por estatus
        const estatusCounts = {
          total: data.length,
          enProceso: data.filter(el => el.estatus === 0).length,
          aprobados: data.filter(el => el.estatus === 1).length,
          conEvidencia: data.filter(el => el.estatus === 2).length,
          rechazados: data.filter(el => el.estatus === 3).length,
        };

        // Calcular el avance
        estatusCounts.avance = estatusCounts.total > 0
          ? ((estatusCounts.aprobados / estatusCounts.total) * 100).toFixed(2)
          : 0;

        // Formatear descripciones con total
        estatusCounts.enProcesoDesc = `${estatusCounts.enProceso} En proceso de ${estatusCounts.total}`;
        estatusCounts.aprobadosDesc = `${estatusCounts.aprobados} Aprobados de ${estatusCounts.total}`;
        estatusCounts.conEvidenciaDesc = `${estatusCounts.conEvidencia} Con evidencia de ${estatusCounts.total}`;
        estatusCounts.rechazadosDesc = `${estatusCounts.rechazados} Rechazados de ${estatusCounts.total}`;
        estatusCounts.avanceDesc = `${estatusCounts.avance}% Avance de 100%`;

        return estatusCounts;
      },

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      this.url = axios.defaults.baseURL  + 'imagenes-entregables/'
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.mis_entregables = []
        return this.$http.get('mis_entregables.list.erp').then(response=>{
        	this.mis_entregables = response.data.filter(el => el.creo == this.getdatosUsuario.iderp)
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.mis_entregables.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.showFileInput = true
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.mis_entregables.indexOf(item)
        this.editedItem = Object.assign({}, item)
        // Mostrar el input de archivos solo al editar
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let fotos = []
        let formData = new FormData();
        formData.append('id', this.editedItem.identregables )
        formData.append('titulo', this.editedItem.titulo )
        formData.append('fecha_entrega', this.editedItem.fecha_entrega )
        formData.append('hora_entrega', this.editedItem.hora_entrega )
        formData.append('notas', this.editedItem.notas )
        formData.append('file', 0)
        formData.append('deleted', 1 )
      	this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('mis_entregables.update.erp/' + this.editedItem.identregables, formData).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.showFileInput = false // Ocultar al cerrar
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio

	      this.cargar = true
        let fotos = []
        let formData = new FormData();
        formData.append('id', this.editedItem.identregables )
        formData.append('titulo', this.editedItem.titulo )
        formData.append('fecha_entrega', this.editedItem.fecha_entrega )
        formData.append('hora_entrega', this.editedItem.hora_entrega )
        formData.append('notas', this.editedItem.notas )
        formData.append('file', this.files)
        formData.append('deleted', 0 )


        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('mis_entregables.update.erp/' + this.editedItem.identregables, formData).then(response=>{
            this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
          this.editedItem.creo = this.getdatosUsuario.iderp
	        // Lo mandapos por el EP
          this.$http.post('mis_entregables.add.erp', this.editedItem).then(response=>{
          	this.initialize()
            this.vistaPrevias = []
	        	this.cargar = false
        		this.close()
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },


      openImageDialog(imagePath) {
        this.fullImageSrc = this.url + imagePath
        this.imageDialog = true
    },

    verMensaje(mensaje){
      this.currentMessage = mensaje; // Asigna el mensaje seleccionado
      this.mensajeDialog = true
    },

      cargarFotos(){
        if(this.files < 1){
          return
        }
      },




      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(file)
          var video = false
          const nombreSplit = file.name.split('.')
          const extension = nombreSplit[nombreSplit-1]
          if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
            video = true
          }
          me.vistaPrevias.push({
            url: reader.result,
            formData: formData,
            image_name: file.name,
            video : video,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

      convertFile(dataurl, filename) {
        var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr,
        u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      },

      createFile(file) {
        if (file.size > 10000000) {
          alert("please check file size no over 5 MB.");
          this.dragging = false;
          return;
        }

        this.file = file;
        this.archivos = file;
        this.getBase64(this.archivos);
        this.dragging = false;
      },

      async pegar(event) {
        if (event.code == "KeyV") {
          const { type, value } = await Clipboard.read();

                    // Validamos que sea de tipo imagen
          if (type == "image/png") {
                    // Convertimos el base64 en una imagen
            const file = this.convertFile(value, "imagen.png");
            this.createFile(file);
          }
        }
      },

      getEstatusTexto(estatus) {
      const estatusMap = {
        0: 'En proceso', // Usuario gray
        1: 'Aprobado', // Abi green
        2: 'Con evidencia', // Usuario blue >Cuando se sube una foto && Brinco el proceso update Todo-Imagen
        3: 'Rechazado' // Abi black
      };
      return estatusMap[estatus];
    },
    getEstatusColor(estatus) {
      const colorMap = {
        0: 'warning',
        1: 'success',
        2: 'info',
        3: 'error'
      };
      return colorMap[estatus];
    }
    },
  }
</script>


<style scoped>
.clickable-image {
  cursor: pointer;
  transition: opacity 0.3s;
}
.clickable-image:hover {
  opacity: 0.7;
}
</style>
